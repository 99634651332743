<template>
  <div>
    <a-page-header
      :title="project_data.code + '-' + project_data.name"
      @back="() => $router.go(-1)"
    >
      <a-row type="flex">
        <a-statistic
          v-for="(i, index) in head_data"
          :title="i.label"
          :key="index"
          :style="
            index != 2
              ? {
                  marginRight: '60px',
                }
              : {
                  marginRight: '60px',
                  borderRight: '1px solid #eee',
                  paddingRight: '60px',
                }
          "
          :value="i.value ? i.value : 0"
        />
      </a-row>
    </a-page-header>
    <a-card title="团队分配" :bordered="false">
      <div style="width: 800px; padding-bottom: 10px; text-align: right">
        <a-button
          type="primary"
          style="margin-right: 10px"
          @click="
            modal.openModal('MyDeCreateModal', '添加团队配比', project_data)
          "
        >
          添加团队配比
        </a-button>
      </div>
      <a-table
        style="width: 800px"
        :dataSource="TeamAssignmentData"
        bordered
        rowKey="id"
        :pagination="false"
        :columns="TeamAssignmentColumns"
      >
        <template #my_button="{ record }">
          <a-button
            type="primary"
            style="margin-right: 10px"
            @click="
              () => {
                record.percentage_number =
                  project_data.percentage_number - record.ratio
                modal.openModal('MyDeUpdateModal', '修改团队配比', record)
              }
            "
          >
            修改
          </a-button>
          <my-delete-button
            popTitle="是否删除?"
            icon="DeleteOne"
            title="删除"
            @confirm="onDeleteChange(record.id)"
          />
        </template>
      </a-table>
    </a-card>
    <a-card title="历史申请单" :bordered="false">
      <a-table
        :dataSource="historyData"
        :pagination="false"
        bordered
        rowKey="id"
        :columns="historyColumns"
      >
        <template #status="{ record }">
          <a-tag :color="tagColor[record.status]">
            {{ record.status }}
          </a-tag>
        </template>
      </a-table>
    </a-card>
    <my-modal
      v-model:show="modal.show.value"
      :title="modal.title.value"
      :width="600"
    >
      <component
        :is="modal.name.value"
        :data="modal.data"
        @finish="modal.onFinish"
        @closeModel="modal.show.value = false"
      />
    </my-modal>
  </div>
</template>
<script>
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  import { onMounted, reactive, ref } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import { useRouter, useRoute } from 'vue-router'
  import MyDepartmentSelect from '@/common/components/Select/MyDepartmentSelect.vue'
  import MyDeCreateModal from '../modal/DeCreateModal.vue'
  import MyDeUpdateModal from '../modal/DeUpdateModal.vue'

  export default {
    inject: ['formLayout', 'tagColor'],
    emits: ['finish'],
    components: { MyDepartmentSelect, MyDeCreateModal, MyDeUpdateModal },
    setup(props, context) {
      const amount_has_been_used = ref()
      const route = useRoute()
      const head_data = ref([])
      const project_data = ref({})
      const historyData = ref([])

      const modal = {
        name: ref('MyDeCreateModal'),
        title: ref(''),
        data: ref({}),
        show: ref(false),
        onCancel: () => {
          //关闭
          modal.show.value = false
        },
        openModal: (name, title, data) => {
          modal.name.value = name
          modal.title.value = title
          modal.data.value = data
          modal.show.value = true
        },
        onFinish: () => {
          //关闭/添加成功
          modal.show.value = false
          onShow()
        },
      }

      const historyColumns = [
        {
          key: 'id',
          title: 'id',
          dataIndex: 'id',
        },
        {
          key: 'code',
          title: '验收单编号',
          dataIndex: 'code',
        },
        {
          key: 'susername',
          title: '制作公司',
          dataIndex: 'supplier.username',
        },
        {
          key: 'total_price',
          title: '总价格',
          customRender: ({ text }) => {
            return '¥' + text.total_price
          },
        },
        {
          key: 'username',
          title: '申请人',
          dataIndex: 'create_user.username',
        },
        {
          key: 'created_at',
          title: '申请时间',
          dataIndex: 'created_at',
        },
        {
          key: 'status',
          title: '状态',
          slots: { customRender: 'status' },
        },
      ]
      const TeamAssignmentData = ref([])
      const TeamAssignmentColumns = [
        {
          key: 'name',
          title: '团队名称',
          dataIndex: 'department.name',
        },
        {
          key: 'ratio',
          title: '分配比例',
          customRender: ({ text }) => {
            return text.ratio + '%'
          },
        },
        {
          key: 'allot_amount',
          title: '金额',
          customRender: ({ text }) => {
            return (
              '¥' +
              (
                amount_has_been_used.value *
                (parseFloat(text.ratio) / 100)
              ).toFixed(2)
            )
          },
        },
        {
          key: 'my_button',
          title: '操作',
          slots: { customRender: 'my_button' },
        },
      ]

      const onDeleteChange = (id) => {
        requests({
          url: API.project_department_ratios.Destroy,
          data: { id: id },
        }).then(() => {
          notification['success']({
            message: '提示',
            description: '删除成功！',
          })
          onShow()
        })
      }

      const onShow = () => {
        requests({
          url: API.Projects.Show,
          data: route.query,
        })
          .then((res) => {
            head_data.value = res.statistics
            project_data.value = res.data.project
            project_data.value.percentage_number = 0
            TeamAssignmentData.value = res.data.team
            res.data.team.forEach((json) => {
              project_data.value.percentage_number += parseFloat(json.ratio)
            })
            res.statistics.forEach((json) => {
              if (json.label == '已用金额') {
                amount_has_been_used.value = parseFloat(json.value)
              }
            })
            historyData.value = res.data.histories
            console.log(res, 'skk3')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      onMounted(() => {
        onShow()
      })
      return {
        head_data,
        project_data,
        TeamAssignmentData,
        TeamAssignmentColumns,
        historyData,
        historyColumns,
        modal,
        onDeleteChange,
      }
    },
  }
</script>
