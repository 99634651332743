<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <div v-if="project_data.percentage_number" style="padding-bottom: 10px">
      当前配比综合：{{ project_data.percentage_number }}%
    </div>
    <a-form-item
      label="选择团队："
      name="departments_id"
      v-bind="validateInfos['departments_id']"
    >
      <MyDepartmentSelect v-model:value="model.departments_id" />
    </a-form-item>
    <a-form-item
      label="比例分配："
      name="ratio"
      v-bind="validateInfos['ratio']"
    >
      <a-input
        type="text"
        placeholder="分配比例"
        addonAfter="%"
        v-model:value="model.ratio"
      />
    </a-form-item>
    <!-- <a-form-item
      label="分配金额："
      name="allot_amount"
      v-bind="validateInfos['allot_amount']"
    >
      <a-input
        type="text"
        addonBefore="¥"
        placeholder="分配金额"
        v-model:value="model.allot_amount"
      />
    </a-form-item> -->

    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit">保存</a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { onMounted, reactive, ref } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import MyDepartmentSelect from '@/common/components/Select/MyDepartmentSelect.vue'
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  export default {
    name: 'de_create_form',
    inject: ['formLayout'],
    emits: ['finish'],
    components: { MyDepartmentSelect },
    props: {
      data: {
        type: Object,
      },
    },
    setup(props, context) {
      const project_data = ref({})
      let validator_ratio = async (rule, value) => {
        if (project_data.value.percentage_number + parseFloat(value) > 100) {
          return Promise.reject('比例分配总值大于100%')
        } else if (!value) {
          return Promise.reject('请输入分配比值！')
        } else {
          return Promise.resolve()
        }
      }
      const modelRef = reactive({
        //表单
        id: undefined,
        projects_id: undefined,
        departments_id: undefined,
        ratio: undefined,
        allot_amount: undefined,
      })
      const rulesRef = reactive({
        //校验
        departments_id: [
          {
            required: true,
            type: 'number',
            message: '请选择团队！',
            trigger: 'change',
          },
        ],
        ratio: [
          {
            required: true,
            type: 'string',
            trigger: 'change',
            validator: validator_ratio,
          },
        ],
        // allot_amount: [
        //   {
        //     required: true,
        //     type: 'string',
        //     message: '请设置分配金额！',
        //     trigger: 'change',
        //   },
        // ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
      const onSubmitForm = () => {
        requests({
          url: API.project_department_ratios.Update,
          data: modelRef,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '修改成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const rformProps = {
        modelRef,
        rulesRef,
      }
      const onchangeTime = (dates, dateStrings) => {
        modelRef.created_date = dateStrings
        console.log(dateStrings, '时间')
      }
      onMounted(() => {
        const data = props.data.value
        project_data.value = data
        modelRef.id = data.id
        modelRef.projects_id = data.projects_id
        modelRef.departments_id = data.departments_id
        modelRef.ratio = data.ratio
        modelRef.allot_amount = data.allot_amount
      })

      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
        onchangeTime,
        project_data,
      }
    },
  }
</script>
